.ant-card,
.ant-tabs {
  &.shadow {
    box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14),
      0 0 1px 0 rgba(0, 0, 0, 0.14);
  }
}

.ant-tabs {
  &.background-white {
    background-color: #ffffff;
  }
}

.ant-table-wrapper {
  box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14), 0 0 1px 0 rgba(0, 0, 0, 0.14);

  &.box-shadow-none {
    box-shadow: none;
  }

  .ant-table {
    .ant-table-container {
      .ant-table-thead {
        font-size: 11px;

        .ant-table-column-sorters {
          width: 100%;

          > span {
            width: 100%;
            margin-right: -20px;

            &.ant-table-column-sorter {
              width: 12px;
              margin-right: 0;
            }
          }
        }
      }

      .ant-table-tbody {
        .ant-table-row {
          &.row-blocked {
            background-color: #d2f0ff;
          }
          &.row-not-hired {
            background-color: #faebfc;
          }
          &.row-not-hired-and-blocked {
            background: linear-gradient(90deg, #d2f0ff, #faebfc);
          }
        }
      }
    }
  }

  &.title-red,
  &.title-blue {
    .ant-table-title {
      color: #ffffff;
      font-size: 12px;
      padding: 13px;

      .table-icon-title {
        font-size: 18px;
        position: absolute;
        right: 15px;
        top: 8px;
      }
    }
  }

  &.title-red {
    .ant-table-title {
      background-color: red;
    }
  }

  &.title-blue {
    .ant-table-title {
      background-color: #070b3a;
    }
  }
}

.ant-dropdown-menu {
  .ant-dropdown-menu-item {
    &.item-approve {
      color: green;
    }

    &.item-reject {
      color: red;
    }
  }
}

.ant-row {
  &.ant-form-item {
    &.slider-range-tooltip {
      .ant-col.ant-form-item-label {
        margin-bottom: 35px;
      }
    }
  }
}

.ant-form {
  &.ant-form-vertical {
    &.custom-form {
      .ant-col.ant-form-item-label {
        padding-bottom: 2px;
      }
    }
  }
}

.e-today {
  display: none !important;
}

.ant-select-dropdown {
  z-index: 1080;
}

.e-btn.e-flat.e-primary,
.e-css.e-btn.e-flat.e-primary,
.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text {
  color: #1890ff;
  border-radius: 2px;
  background-color: #f5f6f6;
}

.e-calendar .e-content td.e-today.e-selected span.e-day {
  border: 1px solid #1890ff;
}

.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-calendar .e-content td.e-selected span.e-day {
  background-color: #1890ff;
}

.ant-upload.ant-upload-drag {
  &.upload-no-padding {
    > .ant-upload.ant-upload-btn {
      padding: 0;
    }
  }
}

.form-group {
  .ant-picker {
    width: 100%;
    margin-bottom: 16px;
  }
}

.e-schedule .e-timeline-month-view .e-selected-cell {
  background-color: #fff;
}

/*cells dont change colour on hover on month*/
.e-schedule
  .e-timeline-month-view
  .e-work-cells:hover:not(.e-resource-group-cells) {
  background-color: #fff;
}

/*cells dont change colour on hover*/
.e-schedule .e-timeline-view .e-work-cells:hover:not(.e-resource-group-cells) {
  background-color: #fff;
}

/*Delete background color on focus */
.e-schedule .e-timeline-view .e-selected-cell {
  background-color: #fff;
}

// .e-appointment-container {
//   margin-top: 7px;
// }

.e-schedule .e-timeline-view .e-resource-left-td {
  width: 230px;
}

.e-schedule .e-spinner-pane {
  display: none !important;
}

.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 230px;
}

.e-toolbar .e-tbar-btn:hover,
.e-toolbar .e-tbar-btn:focus {
  background-color: #f3f4f6;
}

.e-schedule .e-timeline-month-view .e-date-header-wrap table td.e-current-day {
  color: #1890ff;
}

.remove-img {
  position: absolute;
  top: -26px;
  right: 0;
}

.ant-notification {
  z-index: 1100;
}

.ant-btn {
  &.btn-white {
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
  }
}

.ant-picker-dropdown {
  z-index: 2200;
}

.ant-tooltip {
  &.ant-slider-tooltip {
    z-index: 2001;
  }
}

.ant-popover {
  &.ant-popconfirm {
    z-index: 2100;
  }
}

.ant-badge {
  .ant-badge-count {
    font-family: "Proxima Nova Lt", sans-serif, Arial;
    font-weight: 600;
    font-style: normal;
  }
}

.pagination {
  display: flex;
  align-items: center;
  height: 64px;
  margin: 0 15px;

  .ant-pagination-prev:hover .ant-pagination-item-link {
    border-color: #1890ff;
    color: #1890ff;
  }

  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: #1890ff;
    color: #1890ff;
  }

  .ant-pagination-item,
  .ant-pagination-total-text {
    height: 32px;
    line-height: 32px;
  }

  .ant-select-borderless .ant-select-selector {
    background-color: #ffffff !important;
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
  }

  #availability-container
    .pagination
    .ant-select-borderless
    .ant-select-selector {
    background-color: #ffffff !important;
    border-color: #d9d9d9 !important;
  }

  .ant-select:not(.ant-select-customize-input):not(.ant-select-disabled)
    :not(.ant-select-selector:hover).ant-select-selector {
    border-color: #d9d9d9 !important;
  }
}

.e-toolbar-right {
  .e-toolbar-item.e-today,
  .e-toolbar-item.e-schedule-seperator.e-separator {
    display: none;
  }
}

.e-schedule .e-timeline-month-view .e-appointment,
.e-schedule .e-timeline-view .e-appointment {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 12px;
  border-radius: 10px;
  height: 24px;
  color: #ffffff;
  font-weight: normal;
  height: 24px;
}

.ant-pagination {
  &.ant-table-pagination {
    &.ant-table-pagination-right {
      .ant-pagination-prev,
      .ant-pagination-item,
      .ant-pagination-next {
        margin-right: 3px !important;
        min-width: 28px !important;
        height: 28px !important;
        line-height: 26px !important;

        .nofollow {
          padding: 0 4px !important;
        }
      }
    }
  }
}

.tags-container {
  margin-bottom: 20px;
  display: flex;
  gap: 7px;
  flex-wrap: wrap;

  .ant-tag {
    font-size: 16px;
    border-radius: 8px;
    padding: 4px 10px;

    .field {
      font-weight: bold;
      margin-right: 5px;
      color: $font-color-primary-text;
    }
  }
}

.ant-spin-nested-loading {
  > div {
    > .ant-spin {
      max-height: none;
    }
  }
}
