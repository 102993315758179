.template-login {
	background-image: url("../img/login/background.png");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 100vh;

	.container-background {
		//background-color: #2b303b;
		height: 100vh;
		display: flex;
	}

	.logo {
		width: 180px;
	}

	.main-login {
		background-color: rgba(255, 255, 255, 0.95);
		min-height: 100vh;
	}

	.title-form {
		font-size: 18px;
	}

	.link-recover-password {
		color: $font-color-primary-text;
	}
}
