.map-container {
  height: 300px;
  position: relative;
  width: 100%;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.red {
  background: #ea2c54;
}

.green {
  background: rgb(25, 173, 121);
}

.yellow {
  background: rgb(250, 173, 20);
}

.detail-section-container {
  display: flex;
  gap: 25px;
  margin-top: 15px;

  p {
    margin: 0;
  }

  .status-data {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .column-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
