.element-reserved {
	background-color: #E2C6FF;
}

.element-in-campaign {
	background-color: #ffcac6;
}

#chart-cities-amchart {
	> div {
		min-height: 200px;
	}
}

.segment-color {
	float: left;
	cursor: pointer;
	overflow: hidden;
}

.legend-setting{
	width: 16px;
	height: 16px;
}

.mapboxgl-popup{
	z-index: 2;
}
