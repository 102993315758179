.panel-admin {
  .trigger {
    background-color: #fff;
    padding: 10px;
    border-radius: 0 5px 5px 0;
  }

  .logo {
    height: 60px;
    line-height: 60px;
    padding-left: 20px;

    img {
      width: 140px;

      &.logo-collapsed {
        height: 20px;
        width: 20px;
        margin-left: 10px;
      }
    }
  }

  .ant-layout-sider {
    background-color: #ffffff;
    height: 100vh;
    overflow: auto;
    position: fixed;
    left: 0;
  }

  .site-layout {
    transition: 0.2s;

    .ant-breadcrumb {
      background-color: #fff;
      padding: 15px;
      margin: 5px 20px 0 20px;
    }

    .site-layout-header {
      background-color: $background-color-body;
      padding: 0 20px 0 0;

      .trigger {
        font-size: 18px;
      }

      .divider-header {
        border-color: rgba(6, 18, 35, 0.14);
        height: 25px;
        margin: 0 15px;
      }
    }
  }

  .panel-content {
    padding: 20px;
    min-height: calc(100vh - 161px);
  }
}
