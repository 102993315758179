.status-link {
  display: flex;
  align-items: center;
  color: #0099d8;

  .name {
    margin-bottom: 0;
    width: 190px;
  }
}
