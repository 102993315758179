.mapboxgl-popup-content {
  max-height: 360px !important;
  max-width: 360px !important;
  overflow-y: auto !important;
  overflow-x: auto !important;
}

.popup-divider {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
